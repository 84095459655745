<template>
	<div class="login">
		<div style="width: 1355px;margin:0px auto;" class="clearfix">
			<div class="loginImg">
				<div
					style="margin-top:100px;font-size: 40px; font-family: Alimama ShuHeiTi; font-weight: bold; color: #FFFFFF;">
					统一登录平台</div>
				<div style="font-size: 26px; font-family: Alimama ShuHeiTi; color: #FFFFFF;margin: 20px 0px;">
					统一登录平台是工程数据中台的登录入口</div>
				<el-row class="leftList" :gutter="40">
					<el-col :span="6">
						<div :class="selectIndex=='0'?'seleDiv':'DivSty'" @click="selectClick('0')">
							<img src="../../assets/images/bigdata.png" alt="">
							<h3>大数据平台</h3>
						</div>
					</el-col>
					<el-col :span="6">
						<div :class="selectIndex=='1'?'seleDiv':'DivSty'" @click="selectClick('1')">
							<img src="../../assets/images/sf.png" alt="">
							<h3>算法平台</h3>
						</div>
					</el-col>
					<el-col :span="6">
						<div :class="selectIndex=='2'?'seleDiv':'DivSty'" @click="selectClick('2')">
							<img src="../../assets/images/zn.png" alt="">
							<h3>智能云平台</h3>
						</div>
					</el-col>
					<el-col :span="6">
						<div :class="selectIndex=='3'?'seleDiv':'DivSty'" @click="selectClick('3')">
							<img src="../../assets/images/dp.png" alt="">
							<h3>大屏展示</h3>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="content">
				<div class="title">
					<div>欢迎<font>登录大数据平台</font>
					</div>
				</div>
				<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form"
					hide-required-asterisk label-width="100px" label-position="top">
					<el-form-item class="form_bg" prop="userName" label="账号" style="width: 100%">
						<img class="img" src="../../assets/images/loginuser.png" alt="" />
						<el-input v-model="loginForm.userName" type="text" auto-complete="off" placeholder="请输入账号"
							style="width: 100%" :class="{ formFocus: formIndex == 1 }">
						</el-input>
					</el-form-item>
					<el-form-item class="form_bg" prop="password" label="密码" style="width: 100%">
						<img class="img" src="../../assets/images/loginPass.png" alt="" />
						<el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="请输入密码"
							show-password @keyup.enter.native="handleLogin" :class="{ formFocus: formIndex == 2 }">
						</el-input>
					</el-form-item>
					<el-form-item class="yzm_bg" prop="captcha" label="验证码">
						<el-row :gutter="20" style="width: 100%;">
							<el-col :span="14">
								<el-input v-model="loginForm.captcha" placeholder="验证码">
								</el-input>
							</el-col>
							<el-col :span="10" class="login-captcha">
								<img class="codeimg" :src="captchaPath" @click="getCaptcha()" alt="">
							</el-col>
						</el-row>
					</el-form-item>
					<el-checkbox v-model="loginForm.rememberMe" style="margin: 0px 0px 25px 0px">自动登录</el-checkbox>
					<el-form-item style="width: 100%; border: none">
						<el-button :loading="loading" size="medium" type="primary" style="
			          width: 100%;
			          height: 50px;
			          background: #2ba1f0;
			          border-radius: 10px;
			          border: none;
			        " @click="dataFormSubmit">
							<span v-if="!loading">登 录</span>
							<span v-else>登 录 中...</span>
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import {
		getUUID
	} from "@/utils";
	import {
		log
	} from "util";
	export default {
		data() {
			return {
				// 选中系统id
				selectIndex: 0,
				loading: false,
				loginForm: {
					userName: "",
					password: "",
					captcha: "",
					uuid: "",
				},
				formIndex: "0",
				loginRules: {
					userName: [{
						required: true,
						trigger: "blur",
						message: "请输入您的账号"
					}, ],
					password: [{
						required: true,
						trigger: "blur",
						message: "请输入您的密码"
					}, ],
					captcha: [{
						required: true,
						trigger: "blur",
						message: "验证码不能为空"
					}],
				},
				captchaPath: "",
			};
		},
		watch: {
			selectIndex: function(newVal, oldVal) {
				console.log("监视到值的变化");
				console.log(newVal);
				this.getCaptcha();
			},
		},
		created() {
			this.getCaptcha();
		},
		methods: {
			selectClick(index) {
				if (index == '1' || index == '3') {
					this.$message.error("功能正在开发");
				} else {
					this.selectIndex = index;
					console.log(this.selectIndex);
				}

			},
			//获取当前用户信息
			async getcurrUserInfo() {
				await axios.get('/bigdata/sys/user/info', {
						params: this.$http.adornParams()
					})
					.then(({
						data
					}) => {
						if (data && data.code === 0) {
							console.log("获取当前用户信息", data.user);
							window.sessionStorage.setItem("user", JSON.stringify(data.user)); //前端保存账户数据
						}
					})
			},
			// 提交表单
			dataFormSubmit() {
				this.$refs["loginForm"].validate((valid) => {
					//选中的是大数据系统
					if (valid) {
						var params = {
							username: this.loginForm.userName,
							password: this.loginForm.password,
							uuid: this.loginForm.uuid,
							captcha: this.loginForm.captcha,
						};

						axios
							.post("/bigdata/sys/login", params)
							.then((response) => {
								console.log("大数据系统登录成功");
								console.log(response.data);
								this.getcurrUserInfo(); //前端保存账户数据
								this.$cookie.set("token", response.data.token);
								//跳转到大数据主页面
								if (this.selectIndex == 0) {
									this.$router.replace({
										path: "/home"
									});
								}
								//跳转到大屏页面
								if (this.selectIndex == 3) {
									console.log(this.$cookie.get("token"));
									window.location.href = "http://localhost:8082/";
								}
							})
							.catch((error) => {
								console.log(error);
								this.getCaptcha();
								this.$message.error("查询失败");
							});
					}
					if (valid && this.selectIndex == 1) {
						var params = {
							username: this.loginForm.userName,
							password: this.loginForm.password,
							uuid: this.loginForm.uuid,
							captcha: this.loginForm.captcha,
						};

						axios
							.post("/renren-fast/sys/login", params)
							.then((response) => {
								console.log("算法平台登录成功");
								this.$cookie.set("aloToken", response.data.token);
								window.sessionStorage.setItem("alToken", response.data.token); //前端保存账户数据
								// console.log(response.data.token);
								// console.log(window.sessionStorage.getItem("alToken"));
								window.location.href = "http://localhost:8001/#/visi-dataset";
							})
							.catch((error) => {
								console.log(error);
								this.$message.error("查询失败");
							});
					}
					if (valid && this.selectIndex == 2) {
						var params = {
							username: this.loginForm.userName,
							password: this.loginForm.password,
							uuid: this.loginForm.uuid,
							captcha: this.loginForm.captcha,
						};
					
						axios
							.post("renren-fast/sys/login", params)
							.then((data) => {
								if (data && data.code === 0) {
									console.log("云平台登录成功");
									this.$cookie.set("cloudToken", data.token);  
									this.$cookie.set("Authorization", 'Bearer '+ data.kubeSphereToken);
									window.location.href = "http://localhost:8001/#/visi-dataset"; 
								}else{
									this.getCaptcha();
									this.$message.error(data.msg);
								}
								
							})
							.catch((error) => {
								console.log(error);
								this.$message.error("查询失败");
							}); 
					}
				});
			},
			// 获取验证码
			getCaptcha() {
				this.loginForm.uuid = getUUID();

				console.log("=============");
				// 选中的是大数据系统或者大屏展示
				if (this.selectIndex == 0 || this.selectIndex == 3) {
					axios.get('/bigdata/captcha.jpg', {
							params: {
								uuid: this.loginForm.uuid,
							},
							responseType: "arraybuffer"
						})
						.then(res => {
							this.captchaPath = ("data:image/jpeg;base64," + btoa(new Uint8Array(res.data).reduce((data,
								byte) => data + String.fromCharCode(byte), "")));
							console.log(this.captchaPath);
						})


				}
				// 选中的是算法平台
				if (this.selectIndex == 1) {
					this.captchaPath = this.$http.adornUrl2(
						`/captcha.jpg?uuid=${this.loginForm.uuid}`
					);
				}
				// 选中的是云平台
				if (this.selectIndex == 2) { 
					this.captchaPath = this.$http.adornUrl3(
					  `/captcha.jpg?uuid=${this.loginForm.uuid}`
					);
				}
			},
		},
	};
</script>

<style lang="scss">
	.clearfix:after {
		content: '.';
		clear: both;
		height: 0;
		overflow: hidden;
		display: block;
	}

	.login {
		width: 100%;
		height: 100vh;
		// display: flex;
		// justify-content: center;
		// align-items: center; 
		background-image: url("../../assets/bg.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding: 150px 70px;

		.loginImg {
			width: calc(100% - 600px);
			height: 300px;
			float: left;

		}

		.leftList img {
			position: relative;
			top: 20px;
		}

		.DivSty {
			text-align: center;
			height: 235px;
			position: relative;
			background: url(../../assets/images/botbg.png) no-repeat;
			background-position-y: 90px;
			background-size: 100% auto;
		}

		.leftList div:hover {
			cursor: pointer;
		}

		.leftList h3 {
			color: #fff;
			font-size: 18px;
			position: relative;
			top: 130px;
		}

		.seleDiv {
			text-align: center;
			height: 235px;
			position: relative;
			background: url(../../assets/images/botimgHover.png) no-repeat;
			background-position-y: 35px;
			background-size: 100% auto;
		}

		.seleDiv img:first-child {
			// position: relative;
			// top:70px;
		}

		.content {
			width: 550px;
			height: 600px;
			border-radius: 10px;
			background-color: white;
			float: right;
			box-shadow: 0 1px 8px #c7c7c7;
		}

		.title {
			// width: 100%;
			color: #333;
			padding: 0 50px;
			margin: 50px 0px 30px;
		}

		.title div {
			// margin: 10px 0 0px;
			font-size: 34px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			text-align: left;
			// padding-left: 50px;
			letter-spacing: 2px;

			font {
				font-weight: bold;
				color: #2ba1f0;
			}
		}

		.login-form {
			border-radius: 6px;
			background: #ffffff;
			width: 100%;
			padding: 0 50px;

			.form_bg {
				border-radius: 48px;

				.el-form-item__content {
					width: 100%;
					margin-left: 0 !important;
					height: 48px;
					display: flex;
					align-items: center;
					position: relative;
					border-radius: 5px;
					background-color: #e8f0fe;

					.img {
						position: absolute;
						left: 10px;
						top: 8px;
						width: 20px;
						height: 20px;
						margin-right: -20px;
						z-index: 12;
					}

				}

				.el-form-item__label {
					font-size: 18px !important;
					color: #333;
					padding-right: 0 !important;
					font-weight: bold;
					letter-spacing: 15px;
				}

				.el-input {
					height: 44px;
					border-radius: 5px;
					padding: 0px 0 0px 20px;

					input {
						background: transparent;
						height: 38px;
						border: none;
					}
				}
			}

			.yzm_bg {
				.el-input {
					height: 38px;
					border-radius: 5px;
					padding: 0px;
					background-color: #e8f0fe;

					input {
						background: transparent;
						height: 38px;
						border: none;
					}
				}

				.el-form-item__label {
					font-size: 18px !important;
					color: #333;
					padding-right: 0 !important;
					font-weight: bold;
					letter-spacing: 15px;
				}

				.codeimg {
					width: 120px;
					height: 38px;
				}
			}

			.el-form-item {
				border-radius: 48px;

			}

			.input-icon {
				height: 39px;
				width: 14px;
				margin-left: 2px;
			}
		}

		.login-tip {
			font-size: 13px;
			text-align: center;
			color: #bfbfbf;
		}

		.login-code {
			// width: 33%;
			height: 44px;
			float: right;
			border-radius: 0 48px 48px 0;
			overflow: hidden;

			img {
				height: 48px;
				cursor: pointer;
				border-radius: 0 48px 48px 0;
				vertical-align: middle;
				transform: scale(1.1);
			}
		}

		.el-login-footer {
			height: 40px;
			line-height: 40px;
			position: fixed;
			bottom: 0;
			width: 100%;
			text-align: center;
			color: #fff;
			font-family: Arial;
			font-size: 12px;
			letter-spacing: 1px;
		}

		.login-code-img {
			height: 38px;
		}
	}

	.formFocus {
		//  border: 1px solid#01B782 !important;
		animation: lightfrom 2s ease infinite;
	}

	@keyframes lightfrom {
		0% {
			border: 2px solid #2ba1f0;
		}

		50% {
			border: 2px solid #eaeaec;
		}

		100% {
			border: 2px solid #2ba1f0;
		}
	}

	.lizi {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	@media screen and (min-width: 2100px) {
		.login {
			.content {
				width: 650px;
				top: 300px;
				height: 650px;
			}

			.title {
				padding: 90px 0 5px 0;

				h1 {
					font-size: 36px;
					margin-bottom: 20px;
					letter-spacing: 8px;
				}

				h3 {
					font-size: 26px;
					letter-spacing: 6px;
				}
			}

			.login-form {
				padding: 0 80px 0 80px;

				.el-form-item {
					margin-top: 20px;

					.el-form-item__label {
						font-size: 22px !important;
						font-weight: bold;
						letter-spacing: 15px;
					}

					.el-checkbox {
						margin-top: 20px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1750px) {
		.login {
			.title {
				padding: 50px 0 5px 0;

				h1 {
					font-size: 32px;
					text-align: left;
					padding-left: 50px;
					letter-spacing: 10px;
				}

				h3 {
					font-size: 22px;
					padding-left: 50px;
					text-align: left;
					letter-spacing: 8px;
				}
			}

			.content {
				height: 540px;
				right: 100px;
			}

			.login-form {
				padding: 10px 50px 20px 50px;

				.el-form-item {
					.el-form-item__label {
						font-size: 18px !important;
					}

					.el-form-item__content {
						height: 48px;
					}

					.el-input {
						input {
							height: 44px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1600px) {
		.login {
			.content {
				right: 80px;
			}

			.title {
				padding: 30px 0 5px 0;

				h1 {
					font-size: 32px;
					margin-bottom: 10px;
					letter-spacing: 15px;
				}

				h3 {
					font-size: 20px;
					margin-top: 10px;
					letter-spacing: 10px;
				}
			}

			.login-form {
				padding: 10px 50px 20px 50px;

				.el-form-item {
					.el-form-item__label {
						font-size: 16px !important;
					}

					.el-form-item__content {
						height: 40px;
					}

					.el-input {
						input {
							height: 38px;
						}
					}
				}
			}
		}
	}

	.active {
		background-color: #f1e9e9 !important;

		border: 1px solid #409EFF;
	}

	.site-wrapper.site-page--login {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(38, 50, 56, 0.6);
		overflow: hidden;

		&:before {
			position: fixed;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			content: "";
			background-image: url(~@/assets/img/loginback.jpg);
			background-size: cover;
		}

		.site-content__wrapper {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			padding: 0;
			margin: 0;
			overflow-x: hidden;
			overflow-y: auto;
			background-color: transparent;
		}

		.site-content {
			min-height: 100%;
			padding: 30px 500px 30px 30px;
		}

		.brand-info {
			margin: 220px 100px 0 90px;
			color: #fff;
		}

		.brand-info__text {
			margin: 0 0 22px 0;
			font-size: 48px;
			font-weight: 400;
			text-transform: uppercase;
		}

		.brand-info__intro {
			margin: 10px 0;
			font-size: 16px;
			line-height: 1.58;
			opacity: 0.6;
		}

		.login-main {
			position: absolute;
			top: 0;
			right: 0;
			padding: 150px 60px 180px;
			width: 470px;
			min-height: 100%;
			background-color: #fff;
		}

		.login-title {
			font-size: 16px;
		}

		.login-captcha {
			overflow: hidden;

			>img {
				width: 100%;
				cursor: pointer;
			}
		}

		.login-btn-submit {
			width: 100%;
			margin-top: 38px;
		}
	}
</style>